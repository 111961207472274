<template>
  <div class="box" style="overflow: hidden">
    <!-- 隐藏banner -->
    <!-- <div class="join-header">
      <el-image
        style="height: 19vw"
        src="https://oss.yanxin7.cn/2023-08-11/c67e0a9f8e3491a99a96d512da94ed53"
      ></el-image>
    </div> -->

    <div class="">
      <div class="header">
        <h1>申请加盟</h1>
      </div>
      <div class="content">
        <div class="form-data">
          <h2 style="font-weigth: bolder; margin-bottom: 10px">
            成为城市合伙人
          </h2>
          <div style="color: #888888; font-size: 16px; margin-bottom: 30px">
            加入我们，您将获得广告提成+分红激励
          </div>
          <div style="margin-bottom: 10px">代理类型</div>
          <div class="agency-type">
            <div
              class="agency-item"
              :class="isActive == index && 'is-active'"
              v-for="(item, index) in dateList"
              :key="index"
              @click="handTap(index)"
            >
              <el-image
                style="width: 60px; height: 60px; margin-right: 20px"
                :src="item.url"
              ></el-image>
              <span> {{ item.label }}</span>
            </div>
          </div>
          <label style="margin-bottom: 10px">姓名</label>
          <el-input
            placeholder="请输入姓名"
            v-model="formData.contactName"
            style="margin-bottom: 20px"
          ></el-input>
          <label style="margin-bottom: 10px">电话</label>
          <el-input
            placeholder="请输入电话"
            v-model="formData.contactNumber"
            style="margin-bottom: 20px"
          ></el-input>
          <label style="margin-bottom: 10px">验证码</label>
          <el-row style="margin-bottom: 10px; display: flex">
            <el-col style="width: 270px; margin-right: 10px">
              <el-input
                placeholder="请输入验证码"
                v-model="formData.code"
                style="margin-bottom: 20px"
              ></el-input
            ></el-col>
            <div
              @click="getCode"
              class="getCode"
              :class="isGetCode ? 'getCode-click' : ''"
              type="primary"
              style="width: 190px"
            >
              {{ isGetCode ? `重发验证码(${codeNum}s)` : "获取验证码" }}
            </div>
          </el-row>

          <label style="margin-bottom: 10px">加盟地区</label>

          <el-cascader
            v-if="agentType !== 'city'"
            :props="cascaderProps"
            ref="cascaderRegion"
            v-model="cardAddress"
            placeholder="请选择加盟地区"
            style="width: 100%; margin-bottom: 20px; height: 68px"
            :options="addressData"
          >
          </el-cascader>
          <el-cascader
            v-else
            :props="cascaderProps"
            ref="cascaderRegion"
            v-model="cardAddress"
            placeholder="请选择加盟地区"
            style="width: 100%; margin-bottom: 20px; height: 68px"
            :options="filterRegionList"
          >
          </el-cascader>

          <el-button type="primary" @click="handSubmit" style="width: 100%"
            >提交</el-button
          >
        </div>
      </div>
    </div>

    <div class="flex-between" style="margin-top: 5vw">
      <div class="step flex-between">
        <div class="step-item" @click="handItem(1)">
          <div style="font-weight: bold; margin-bottom: 1vw">平台功能</div>
          <div
            class="a-line1"
            style="color: #888888; font-size: 14px; margin-bottom: 1vw"
          >
            招标投标、资质查询、专属项目、造价…
          </div>
          <div>
            <el-image
              style="width: 1vw; height: 1vw"
              src="https://oss.yanxin7.cn/2023-08-11/116f3822a8b6234cbc0b1a839d37cf16"
            ></el-image>
          </div>
        </div>
        <div class="step-item" @click="handItem(2)">
          <div class="a-line1" style="font-weight: bold; margin-bottom: 1vw">
            平台活动
          </div>
          <div style="color: #888888; font-size: 14px; margin-bottom: 0.5vw">
            VIP服务、表单服务…
          </div>
          <div>
            <el-image
              style="width: 1vw; height: 1vw"
              src="https://oss.yanxin7.cn/2023-08-11/116f3822a8b6234cbc0b1a839d37cf16"
            ></el-image>
          </div>
        </div>
        <div class="step-item" @click="handItem(3)">
          <div style="font-weight: bold; margin-bottom: 1vw">企业简介</div>
          <div
            class="a-line1"
            style="color: #888888; font-size: 14px; margin-bottom: 0.5vw"
          >
            邦建通科技集团股份有限公司…
          </div>
          <div>
            <el-image
              style="width: 1vw; height: 1vw"
              src="https://oss.yanxin7.cn/2023-08-11/116f3822a8b6234cbc0b1a839d37cf16"
            ></el-image>
          </div>
        </div>
        <div class="step-item" @click="handItem(4)">
          <div style="font-weight: bold; margin-bottom: 1vw">联系我们</div>
          <div
            class="a-line1"
            style="color: #888888; font-size: 14px; margin-bottom: 0.5vw"
          >
            共筑数字建筑新蓝图、携手把握数字建…
          </div>
          <div>
            <el-image
              style="width: 1vw; height: 1vw"
              src="https://oss.yanxin7.cn/2023-08-11/116f3822a8b6234cbc0b1a839d37cf16"
            ></el-image>
          </div>
        </div>
      </div>
    </div>
    <div class="join-zc">
      <div style="font-size: 46px; font-weight: bold">加盟政策</div>
      <div class="b-line"></div>
      <div class="join-zc-box">
        <div class="zc-item">
          <div style="color: #2979ff; font-weight: bold; margin-bottom: 2vw">
            企业信息
          </div>
          <div class="b-lines"></div>
          <div style="width: 17vw; line-height: 30px">
            企业营业执照、企业开户许可证、企业法人身份证、企业联系方式
          </div>
          <el-image
            class="zc-image"
            style="width: 10vw; height: 10vw"
            src="https://oss.yanxin7.cn/2023-08-11/c0475394322bb640588e312f43d67130"
          ></el-image>
        </div>
        <div class="zc-item">
          <div style="color: #2979ff; font-weight: bold; margin-bottom: 2vw">
            合作范围
          </div>
          <div class="b-lines"></div>
          <div style="width: 17vw; line-height: 30px">
            向全国招募地级市 / 区县合伙人
          </div>
          <el-image
            class="zc-image"
            style="width: 10vw; height: 10vw"
            src="https://oss.yanxin7.cn/2023-08-11/ac18c53947cb04df5a186c356f2af7b2"
          ></el-image>
        </div>
        <div class="zc-item">
          <div style="color: #2979ff; font-weight: bold; margin-bottom: 2vw">
            符合条件
          </div>
          <div class="b-lines"></div>
          <div style="width: 17vw; line-height: 30px">
            从事建筑领域关联行业的创业人群，有创新思维，能吃苦耐劳
          </div>
          <el-image
            class="zc-image"
            style="width: 10vw; height: 10vw"
            src="https://oss.yanxin7.cn/2023-08-11/5113ded375dc5fd2efd63c682791e818"
          ></el-image>
        </div>
        <div class="zc-item">
          <div style="color: #2979ff; font-weight: bold; margin-bottom: 2vw">
            专业要求
          </div>
          <div class="b-lines"></div>
          <div style="width: 17vw; line-height: 30px">
            软件工程、计算机、金融、营销、会计或建筑类相关专业
          </div>
          <el-image
            class="zc-image"
            style="width: 10vw; height: 10vw"
            src="https://oss.yanxin7.cn/2023-08-11/87769c4145e4e367472cd73975b066b0"
          ></el-image>
        </div>
      </div>
    </div>

    <div>
      <el-image
        style="width: 58vw; height: 15vw"
        src="https://oss.yanxin7.cn/2023-08-11/8446738a5b38b4e0d79d3f0c74545530"
      ></el-image>
    </div>

    <div style="margin-top: 5vw">
      <el-image
        style="height: 47vw"
        src="https://oss.yanxin7.cn/2023-08-11/769dd8b5f8837068957267ee89c58c06"
      ></el-image>
    </div>
    <div style="height: 100px"></div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { getLoginSms } from "@src/api/account.js";
import {
  addPartnerApply,
  NEWsendVerifyCode,
  checkVerifyCode,
} from "@src/api/engineering.js";
export default {
  components: {},
  data() {
    return {
      commonResSearchForm: {},
      // 级联选择器参数设置
      cascaderProps: {
        lazy: false,
        value: "name",
        label: "name",
        children: "child",
        expandTrigger: "hover",
        checkStrictly: true,
      },
      formData: {
        agentType: "county",
        contactName: "",
        contactNumber: "",
      },
      cardAddress: "",
      agentType: "city",
      options: [],
      dateList: [
        {
          value: "city",
          label: "市级代理",
          url: "https://oss.yanxin7.cn/2023-09-14/51acca29457cbe0dddaebf5d0c58fe6a",
        },
        {
          value: "county",
          label: "县（区）级代理",
          url: "https://oss.yanxin7.cn/2023-07-03/040941dcc69e7d282be7bd133c4c914e",
        },
      ],
      isActive: 0,
      isGetCode: false,
      codeNum: 59,
      timeInter: null,
    };
  },

  computed: {
    ...mapState("address", ["addressData"]),
    filterRegionList() {
      let newAddr = this.$lodash.cloneDeep(this.addressData);
      for (let i = 0; i < newAddr.length; i++) {
        newAddr[i].child.forEach((item) => (item.child = null));
      }
      return newAddr;
    },
  },
  mounted() {
    document.title = "申请加盟";
    console.log("twoAddress", this.regionList);
  },
  methods: {
    handItem(e) {
      let url = "";
      let params = { params: "" };
      if (e == 1) {
        url = "/websiteIndex/websiteIndex";
      }
      if (e == 2) {
        url = "/dataCenter/dataCenter";
      }
      if (e == 3) {
        url = `/about/about?params=product`;
      }
      if (e == 4) {
        url = `/about/about?params=contentMe`;
      }
      let vRouter = this.$router.resolve({
        path: url,
        params: params,
      });
      console.log("xxs", vRouter);
      window.open(vRouter.href, "_blank");
    },
    getCode() {
      if (!this.formData.contactNumber)
        return this.$message.error("请输入手机号码");
      if (this.isGetCode) return;
      this.isGetCode = true;
      this.timeInter = setInterval(() => {
        this.codeNum = this.codeNum - 1;
        if (this.codeNum <= 0) {
          this.isGetCode = false;
          this.codeNum = 59;
          clearInterval(this.timeInter);
        }
      }, 1000);

      this.getPhoneCode();
    },

    getPhoneCode() {
      let that = this;
      let data = {
        phone: this.formData.contactNumber,
        smsEnum: "PHONE_COMMON",
      };
      NEWsendVerifyCode(data)
        .then((res) => {
          console.log("发送短信成功", res);
          that.$message({ message: "发送短信成功", type: "success" });
        })
        .catch((err) => {
          console.log("短信验证失败", err);
        });
    },

    getCheckedNodes(e) {
      let that = this;
      this.twoAddress.forEach((item) => {
        if (item.value === e[0]) {
          item.children.forEach((child) => {
            if (child.value === e[1]) {
              if (child.label === "直辖市") {
                this.selectValue = item.label;
                this.$store.commit("address/PUBLICADDRESS", item.label);
                return;
              }
              this.selectValue = child.label;
              this.$store.commit("address/PUBLICADDRESS", child.label);
            }
          });
        }
      });
      this.searchShow = false;
    },
    handTap(index) {
      this.formData.agentType = this.dateList[index].value;
      this.isActive = index;
    },
    handSubmit() {
      if (!this.formData.contactName) return this.$message.error("请输入名字");
      if (!this.formData.contactNumber)
        return this.$message.error("请输入手机号码");
      // if (this.codeNum == 59) return this.$message.error("请先获取验证码");
      // if (!this.formData.code) return this.$message.error("请输入验证码");
      if (!this.cardAddress) return this.$message.error("请选择地址");
      this.formData.province = this.cardAddress[0] || "";
      this.formData.city = this.cardAddress[1] || "";
      this.formData.county = this.cardAddress[2] || "";
      this.formData.address = this.cardAddress[1] || "";
      checkVerifyCode({
        verifyCode: this.formData.code,
        phone: this.formData.contactNumber,
        verifyType: 9,
      })
        .then((res) => {
          console.log("res===>", res);
          if (res.code !== 200)
            return that.$message({ message: "验证码错误", type: "error" });
        })
        .catch((err) => {
          console.log("校验验证码", err);
          return that.$message({ message: "验证码错误", type: "error" });
        });
      addPartnerApply(this.formData)
        .then((res) => {
          that.$message({
            message: "提交成功",
            type: "success",
          });
        })
        .catch((err) => {
          that.$message({
            message: err,
            type: "success",
          });
        });

      this.$message({
        message: "提交成功",
        type: "success",
      });
      setTimeout(() => {
        location.reload();
      }, 3000);
    },
  },
};
</script>
<style>
.getCode {
  cursor: pointer;
  width: 190px;
  height: 40px;
  background: #2288fc;
  border: 1px solid #2288fc;
  border-radius: 10px;
  line-height: 40px;
  color: white;
}
.getCode-click {
  background: #8592a2;
  border: 1px solid #8592a2;
}

.header {
  margin-top: 60px;
  margin-bottom: 126px;
  font-weight: bolder;
  font-size: 36px;
}
.content {
  width: 100vw;
  height: 800px;
  background-image: url("https://oss.yanxin7.cn/2023-07-03/f3d295f3a910529d26142a2aaf1a5182");
  background-repeat: no-repeat;
  background-size: 100vw 800px;
  display: flex;
  justify-content: center;
}
.form-data {
  width: 550px;
  height: 783px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #e2e5ec;
  margin-top: -80px;
  padding: 40px 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.agency-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 480px;
  margin-bottom: 35px;
}
.agency-item {
  cursor: pointer;
  width: 230px;
  height: 94px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #8592a2;
  justify-content: center;
  display: flex;
  align-items: center;
}
.is-active {
  border: 3px solid #2288fc;
}
.join-header {
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: center;
}

.step {
  justify-content: space-between;
  width: 62vw;
}
.step-item {
  cursor: pointer;
  box-sizing: border-box;
  width: 15vw;
  height: 7vw;
  background: #ffffff;
  box-shadow: 2px 2px 2px 2px rgba(143, 196, 255, 0.4);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 0px solid #e2e5ec;
  text-align: start;
  padding: 1vw;
}
.join-zc {
  width: 100%;
  background-image: url("https://oss.yanxin7.cn/2023-08-11/86bd707034912516e59bf1a50208e1f2");
  background-repeat: no-repeat;
  height: 52vw;
  margin-top: 5vw;
}
.join-zc-box {
  width: 62vw;
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.zc-item {
  width: 29vw;
  height: 17vw;
  background-color: white;
  position: relative;
  text-align: start;
  box-sizing: border-box;
  padding: 1vw;
  margin-bottom: 1vw;
}
.b-lines {
  width: 4vw;
  height: 2px;
  background: #fb511d;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  margin-bottom: 3vw;
}
.b-line {
  margin-top: 10px;
  width: 2vw;
  border-bottom: 1px solid #2288fc;
  margin-bottom: 5vw;
  margin-left: 50%;
  transform: translateX(-50%);
}
.zc-image {
  position: absolute;
  top: 0;
  right: 0;
}
.a-line1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>